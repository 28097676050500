.content {
  &__body {
    background: #f7faff;
    box-shadow: 0px 24px 40px -15px rgba(92, 102, 115, 0.3);
    border-radius: 12px;

    padding: 0.75rem 2rem 0.75rem 2rem;
    margin-bottom: 1.5rem;

    &-title {
      padding-top: 0;
      padding-left: 0;
      border-radius: 12px;
      .ant-page-header-heading-title {
        color: #98a4bc;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
      .ant-page-header-back {
        margin-right: 8px;
        .ant-page-header-back-button {
          cursor: default;
        }
      }
    }
  }
}

.dnd-content {
  // border: 2px solid green;

  .ant-col-30 {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    max-height: 100%;
    width: 100%;
    padding: 0;
    box-shadow: none;
    // border: 2px solid red;
  }

  .ant-page-header {
    // border: 2px solid blue;
    padding-right: 0;
  }

  .ant-page-header-footer {
    // border: 2px solid blue;
    margin: 0;
  }

  &__body {
    background: #f7faff;
    border-radius: 12px;
    // padding: 0 32px 12px 32px;

    &-title {
      // padding-top: 0;
      // padding-left: 0;

      border-radius: 12px;
      .ant-page-header-heading-title {
        color: #98a4bc;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
      .ant-page-header-back {
        margin-right: 8px;
        .ant-page-header-back-button {
          cursor: default;
        }
      }
    }
  }
}

.vacancies {
  .ant-btn,
  .anticon-cloud-download {
    color: #5061dc;
  }
  .ant-btn-default.ant-btn-sm {
    border-radius: $button-radius;
  }
}
